import React from "react";

const Navbar = () => {
  return (
    <div style={{
        display: "flex",
        width: "100%",
        height: "100px",
        alignItems: "center",
        padding: "0px 20px",
    }}>
    
    </div>
  );
};

export default Navbar;
